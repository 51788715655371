import "@/styles/index.scss";

import "./modules/Navbar";
import "./modules/Colors";
import "./modules/Button";
import "./modules/MobileMenu";
import "./modules/ToBottom";
import { gsap } from "gsap";
import caseHeroLottie from "./lottie-files/hear_our_work.json";
import { textRevealAnimation } from "./animation/index";
import Slider from "./modules/Slider";
import CaseFilter from "./modules/CaseFilter";
import AudioPlayer from "./modules/AudioPlayer";
import AudioFiles from "./modules/AudioFiles";
import VideoPlayer from "./modules/VideoPlayer";
import Lottie from "./modules/Lottie";
import { initScrollAnimations } from "./animation/modules/ScrollAnimations";

class App {
	constructor() {
		this._createSliders();
		this._createAudioPlayers();
		this._createAudioFiles();
		this._createVideoPlayers();
		this._createCaseFilter();
		this._createLotties();
		this._createTextReveals();
		this._createScrollAnimations();
	}

	_createSliders() {
		try {
			const sliders = [...document.querySelectorAll(".slider")];
			if (!sliders.length) return;

			sliders.forEach((slider) => {
				new Slider({
					container: slider,
				});
			});
		} catch (error) {
			console.error("Error creating sliders:", error);
		}
	}

	_createAudioPlayers() {
		try {
			const audioPlayers = [...document.querySelectorAll(".audio-player")];
			if (!audioPlayers.length) return;

			audioPlayers.forEach((audioPlayer) => {
				new AudioPlayer(audioPlayer);
			});
		} catch (error) {
			console.error("Error creating audio players:", error);
		}
	}

	_createAudioFiles() {
		const audioFiles = [...document.querySelectorAll(".audio-files")];
		if (!audioFiles.length) return;

		audioFiles.forEach((audioPlayer) => {
			new AudioFiles(audioPlayer);
		});
	}

	_createVideoPlayers() {
		try {
			const videoPlayers = [...document.querySelectorAll(".video__wrapper")];
			if (!videoPlayers.length) return;

			videoPlayers.forEach((videoPlayer) => {
				new VideoPlayer(videoPlayer);
			});
		} catch (error) {
			console.error("Error creating video players:", error);
		}
	}

	_createCaseFilter() {
		try {
			this.caseFilter = new CaseFilter();
		} catch (error) {
			console.error("Error creating case filter:", error);
		}
	}

	_createLotties() {
		const headerLottie = document.querySelector(".header__lottie");
		const bespokeCompositionDOM = document.querySelector("#bespoke-composition");
		const caseHeroDOM = document.querySelector("#case-hero");
		const pencilDOM = document.querySelector("#pencil");
		const bullHornDOM = document.querySelector("#bullhorn");

		if (headerLottie) {
			if (!window.headerLogo) return;

			new Lottie({
				path: window.headerLogo,
				container: headerLottie,
			});
		}

		if (caseHeroDOM) {
			new Lottie({
				animationData: caseHeroLottie,
				container: caseHeroDOM,
				loop: true,
			});
		}

		if (bespokeCompositionDOM) {
			if (!window.bespokeCompositionLottie) return;
			console.log(window.bespokeCompositionLottie);
			new Lottie({
				path: window.bespokeCompositionLottie,
				container: bespokeCompositionDOM,
				loop: true,
			});
		}

		if (pencilDOM) {
			new Lottie({
				path: window.pencilLottie,
				container: pencilDOM,
				loop: true,
			});
		}
		
		if (bullHornDOM) {
			new Lottie({
				path: window.bullHornLottie,
				container: bullHornDOM,
				loop: true,
			});
		}
	}

	_createTextReveals() {
		textRevealAnimation();
	}

	_createScrollAnimations() {
		initScrollAnimations();
	}
}

new App();

console.log("%c Developed by ANTI - https://anti.as/", "background: #000; color: #fff;");
